import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, SignedIn, UserButton, ClerkProvider, useUser } from '@clerk/clerk-react';
import ChatsPage from './ChatsPage/ChatsPage';
import LoginPage from './LoginPage/LoginPage';
import styled from 'styled-components';
import * as API from '../Domain/API';
import { Colors } from './Styles/ColorPalette';
import { Banner } from './SharedComponents/Banner';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

const InitializeApi = () => {
  const { getToken } = useAuth();

  useEffect(() => {
    API.initialize(); // set base url
    API.setTokenGetter(getToken); // set token getter
  }, [getToken]);

  return null;
};

const App: React.FC = () => {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Router>
        <InitializeApi />
        <AppContent />
      </Router>
    </ClerkProvider>
  );
};

export default App;

const AppContent: React.FC = () => {
  const { isLoaded, isSignedIn } = useAuth();

  const userObject = useUser();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  console.log(userObject);
  const fullName = userObject?.user?.fullName;

  return (
    <AppContainer>
      <Header>
        <Link to="/">
          {' '}
          <Title>🐕‍🦺 AskSchmidt</Title>{' '}
        </Link>
        <UserName>
          {/* @ts-ignore */}
          <SignedIn>
            {fullName}
            <UserButton afterSignOutUrl="/login" />
          </SignedIn>
        </UserName>
      </Header>

      <Content>
        <Routes>
          <Route path="/login" element={isSignedIn ? <Navigate to="/" replace /> : <LoginPage />} />
          <Route
            path="/*"
            element={
              isSignedIn ? (
                <Routes>
                  <Route path="/" element={<ChatsPage />} />
                  <Route path="/chats" element={<ChatsPage />} />
                  <Route path="/chats/:chatId" element={<ChatsPage />} />
                </Routes>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </Content>

      <Footer>
        <FooterText>
          &copy; AskSchmidt 2024
          <span style={{ margin: '0 4px' }}>-</span>
          <a
            href="https://stats.uptimerobot.com/yOKDWSyy8Z"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View system uptime status"
          >
            Uptime
          </a>
          <span style={{ margin: '0 4px' }}>-</span>
          <span style={{ margin: '0 4px' }}>🔵🟡</span>
        </FooterText>
      </Footer>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${Colors.background.primary};
`;

const Header = styled.div`
  background-color: white;
  color: ${Colors.text.primary.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 26px;
  border-bottom: 1px solid ${Colors.border.primary};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  z-index: 1000;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }
`;

const Title = styled.h1`
  font-size: 1.1rem;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: white;
  overflow: hidden;
`;

const Footer = styled.div`
  background-color: ${Colors.background.primary};
  color: ${Colors.text.primary.regular};
  font-weight: bold;
  text-align: center;
  padding: 0 16px;
  border-top: 1px solid ${Colors.border.primary};
`;

const FooterText = styled.p`
  font-size: 0.8rem;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
